import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from "@angular/core";
import { S25IconConst, S25IconType } from "./s25.icon.const";
import { TypeManagerDecorator } from "../../main/type.map.service";

@TypeManagerDecorator("s25-ng-icon")
@Component({
    selector: "s25-ng-icon",
    template: `
        @if (!Util.icons[type].dataImage) {
            <svg
                class="c-svgIcon"
                role="img"
                [style.width]="size"
                [style.height]="size"
                [attr.autoDarkMode]="autoDarkMode && Util.icons[type].hasDarkMode ? 'on' : 'off'"
            >
                <title>{{ label || Util.icons[type].title }}</title>
                <use
                    class="noPointerEvents light"
                    [attr.xmlns:xlink]="'http://www.w3.org/1999/xlink'"
                    [attr.xlink:href]="
                        './resources/typescript/assets/css-compiled/images/sprite.svg#' + Util.icons[type].name
                    "
                ></use>
                @if (Util.icons[type].hasDarkMode) {
                    <use
                        class="noPointerEvents dark"
                        [attr.xmlns:xlink]="'http://www.w3.org/1999/xlink'"
                        [attr.xlink:href]="
                            './resources/typescript/assets/css-compiled/images/sprite.svg#' +
                            Util.icons[type].name +
                            '--dark'
                        "
                    ></use>
                }
            </svg>
        }

        @if (!!Util.icons[type].dataImage) {
            <div
                [style.background-image]="'url(' + Util.icons[type].dataImage + ')'"
                [style.height]="Util.icons[type].size.height"
                [style.width]="Util.icons[type].size.width"
                class="dataImage"
            ></div>
        }
    `,
    styles: `
        :host {
            display: inline-block;
        }

        .noPointerEvents {
            pointer-events: none;
        }

        .dark {
            display: none;
        }

        ::ng-deep .nm-party--on s25-ng-icon [autoDarkMode="on"] .light {
            display: none;
        }

        ::ng-deep .nm-party--on s25-ng-icon [autoDarkMode="on"] .dark {
            display: initial;
        }

        .dataImage {
            background-repeat: no-repeat;
            background-position: center;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.Emulated,
})
export class S25IconComponent {
    @Input({ required: true }) type: S25IconType;
    @Input() label: string;
    @Input() size: number | string;
    @Input() autoDarkMode = false;

    Util = S25IconConst;
}
