import { NgModule } from "@angular/core";
import { S25IconComponent } from "./s25.icon.component";
import { CommonModule } from "@angular/common";

@NgModule({
    imports: [CommonModule],
    exports: [S25IconComponent],
    declarations: [S25IconComponent],
    providers: [S25IconComponent],
})
export class S25IconModule {}
