import { Directive, ElementRef, HostListener, Input } from "@angular/core";
import { HelpTopic, S25Help } from "./s25.help.service";

@Directive({
    selector: "[s25Help]",
    standalone: true,
})
export class S25HelpDirective {
    @Input() helpTopic: HelpTopic;

    constructor(private elementRef: ElementRef) {}

    @HostListener("click", ["$event"])
    @HostListener("keydown.enter", ["$event"])
    @HostListener("keydown.space", ["$event"])
    @HostListener("auxclick", ["$event"]) // Listen for center click
    public getHelp(event: Event) {
        if (!(event instanceof MouseEvent && event.button === 2)) {
            return S25Help.help(this.helpTopic, event);
        }
    }
}
