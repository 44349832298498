//author: devin
import { S25FavoriteComponent } from "./s25.favorite.component";
import { jSith } from "../../util/jquery-replacement";

export class FavoriteApi {
    public static Instances: S25FavoriteComponent[] = [];

    static refreshInstances(data?: any) {
        jSith.forEach(FavoriteApi.Instances, (_: any, instance: S25FavoriteComponent) => {
            //if same id and type, update fav and view
            if (
                instance.item &&
                instance.isLoggedIn &&
                parseInt(instance.item.itemId) === parseInt(data.itemId) &&
                parseInt(instance.item.itemTypeId) === parseInt(data.itemTypeId)
            ) {
                instance.refresh(data.isFav);
            }
        });
    }
}
