import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Item } from "../../pojo/Item";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { FavoriteService } from "../../services/favorite.service";
import { Debounce } from "../../decorators/debounce.decorator";

@TypeManagerDecorator("s25-ng-favorite-simple")
@Component({
    selector: "s25-ng-favorite-simple",
    template: `
        @if (loggedIn) {
            <input
                type="checkbox"
                class="star"
                [class.ngStarOn]="starred"
                [class.ngStarOff]="!starred"
                [attr.aria-label]="'Toggle favorite for ' + itemName"
                [ngModel]="starred"
                (ngModelChange)="setFavorite($event)"
            />
        }
    `,
    styles: `
        :host {
            display: flex !important;
        }

        .star {
            appearance: none;
            margin: auto;
            width: 16px;
            height: 16px;
            background-repeat: no-repeat;
            cursor: pointer;
        }
    `,
})
export class S25FavoriteSimpleComponent implements OnInit {
    @Input() starred: boolean;
    @Input() itemId: number;
    @Input() itemType: Item.Id;
    @Input() itemName: string;
    @Input() loggedIn: boolean = false;

    @Output() starredChange = new EventEmitter<boolean>();

    constructor(private changeDetector: ChangeDetectorRef) {}

    async ngOnInit() {}

    async setFavorite(on: boolean) {
        if (on === this.starred) return;
        this.starred = on;
        this.changeDetector.detectChanges();
        const [_, error] = await S25Util.Maybe(this.updateFavorite(on));
        if (!error) this.starredChange.emit(on);
        else {
            S25Util.showError(error, "There was an update when updating the favorite status.");
            this.starred = !this.starred;
        }
        this.changeDetector.detectChanges();
    }

    @Debounce(300)
    async updateFavorite(on: boolean) {
        if (on) return FavoriteService.add({ itemId: this.itemId, itemTypeId: this.itemType });
        return FavoriteService.remove({ itemId: this.itemId, itemTypeId: this.itemType });
    }
}
