import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { FileUploadComponent } from "./s25.file.upload.component";
import { S25LoadingInlineModule } from "../s25-loading/s25.loading.inline.module";

@NgModule({
    declarations: [FileUploadComponent],
    imports: [CommonModule, FormsModule, S25LoadingInlineModule],
    providers: [FileUploadComponent],
    exports: [FileUploadComponent],
})
export class FileUploadModule {
    constructor() {}
}
