//@author: devin

import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewEncapsulation,
} from "@angular/core";
import { FavoriteService } from "../../services/favorite.service";
import { UserprefService } from "../../services/userpref.service";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { FavoriteApi } from "./favorite.api";
import { jSith } from "../../util/jquery-replacement";

@TypeManagerDecorator("s25-favorite")
@Component({
    selector: "s25-favorite",
    template: ` <ng-content></ng-content> `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25FavoriteComponent implements OnInit, OnChanges {
    @Input() item: any = undefined;

    isLoggedIn: Boolean = false;

    constructor(private elementRef: ElementRef) {
        FavoriteApi.Instances.push(this);
    }

    refresh(isFav: Boolean) {
        this.item.isFav = isFav;
        this.starClass();
    }

    getElement() {
        return this.elementRef.nativeElement.children.length && this.elementRef.nativeElement.children[0];
    }

    starClass() {
        let element = this.getElement();
        if (element) {
            jSith.removeClass(element, "ngStarOn");
            jSith.removeClass(element, "ngStarOff");
            if (this.item.isFav) {
                jSith.addClass(element, "ngStarOn");
            } else {
                jSith.addClass(element, "ngStarOff");
            }
        }
    }

    toggleFav() {
        FavoriteService.toggle(this.item);
        this.item.isFav = !this.item.isFav;
        this.item.favorite = this.item.isFav ? "T" : "F";
    }

    onAction() {
        this.toggleFav(); //toggle fav and persist
        //update stars for this and anywhere else this is starred
        FavoriteApi.refreshInstances({
            isFav: this.item.isFav,
            itemId: this.item.itemId,
            itemTypeId: this.item.itemTypeId,
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.item && !changes.item.firstChange) {
            this.item.isFav = this.isFav();
            this.starClass();
        }
    }

    isFav() {
        return S25Util.coalesce(this.item.isFav, this.item.isFav == 1 || this.item.favorite === "T");
    }

    ngOnInit() {
        let element = this.getElement();
        if (element) {
            element.angBridge = this;

            this.isLoggedIn = false;
            jSith.addClass(element, "ngMinImgDim ngCpointer");

            if (this.item && this.item.itemId) {
                this.item.onAction = this.onAction;

                UserprefService.getLoggedIn().then((isLoggedIn) => {
                    this.isLoggedIn = isLoggedIn;
                    if (this.isLoggedIn) {
                        this.item.isFav = this.isFav(); //set isFav to boolean

                        jSith.on(element, "click", (e: Event) => {
                            //bind click to toggling of favorite
                            e && e.stopPropagation && e.stopPropagation(); //prevent another click from being processed
                            this.onAction();
                        });

                        this.starClass(); //init class on element
                    }
                });
            }
        }
    }
}
