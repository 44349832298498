//@author: devin

import { NgModule } from "@angular/core";
import { S25FavoriteComponent } from "./s25.favorite.component";
import { S25FavoriteSimpleComponent } from "./s25.favorite.simple.component";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";

@NgModule({
    declarations: [S25FavoriteComponent, S25FavoriteSimpleComponent],
    imports: [FormsModule, CommonModule],
    providers: [S25FavoriteComponent, S25FavoriteSimpleComponent],
    exports: [S25FavoriteComponent, S25FavoriteSimpleComponent],
})
export class S25FavoriteModule {}
