//@author: devin

import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { HelpTopic, S25Help } from "./s25.help.service";
import { TypeManagerDecorator } from "../../main/type.map.service";

@TypeManagerDecorator("s25-help-link")
@Component({
    selector: "s25-help-link",
    template: `
        <div
            s25Help
            [helpTopic]="helpTopic"
            class="ngInlineBlock"
            aria-label="Help"
            role="button"
            tabindex="0"
            [class.link]="styleAsLink"
        >
            <ng-content></ng-content>
        </div>
    `,
    styles: `
        :host {
            --link-color: #2573a7;
        }

        ::ng-deep .nm-party--on s25-help-link {
            --link-color: #6faee2;
        }

        .link {
            color: var(--link-color);
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25HelpComponent implements OnInit {
    @Input() helpTopic: HelpTopic;
    @Input() styleAsLink: boolean = false;

    constructor(private elementRef: ElementRef) {}

    ngOnInit() {
        this.elementRef.nativeElement.angBridge = this;
    }
}
