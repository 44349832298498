import { DataAccess } from "../../dataaccess/data.access";
import { S25Util } from "../../util/s25-util";
import { UserprefService } from "../../services/userpref.service";

const REMOTE_SYSTEM = "knowledgeowl"; //use 'admin-test' to reach knowledgeowl testuth.knowledgewol.com
const HELP_DOMAIN = `https://knowledge25.knowledgeowl.com/`;
const helpHome = `${HELP_DOMAIN}help/25live/`;
const helpBase = `${HELP_DOMAIN}help/`;

export class KnowledgeOwlService {
    public static token: string;
    /**
     *
     * @param event - likely a click event, will stop propogation of the action
     * @param articlePath - The path to the article desired appended to help home
     */
    public static async openKORemote(event: Event, articlePath?: string) {
        event?.preventDefault();
        // otherwise onOpen runs twice due to internal ko script handler
        event?.stopImmediatePropagation();

        let articleUrl = await KnowledgeOwlService.getAuthUrl(event, articlePath);
        setTimeout(() => {
            goToLink(articleUrl);
        }, 100);

        return articleUrl;
    }

    /**
     *
     * When auth is required - append auth info to help url.
     *
     * @param event - Click event - if no article path is provided we try to fetch href for the url
     * @param articlePath - optional article path, either complete url OR final
     */
    public static async getAuthUrl(event: Event, articlePath?: string) {
        let articleUrl: string;
        if (articlePath?.includes("http")) {
            articleUrl = articlePath;
        } else {
            articleUrl = extractUrl(event) || (articlePath && helpBase) ? `${helpBase}${articlePath}` : helpHome;
        }

        //constructing a URL its easy to end up with double slashes - leave https:// unchanged
        articleUrl = articleUrl.replace(/([^:])\/\//g, "$1/");
        const token = await KnowledgeOwlService.getRemoteTokenFromSeries25();
        return token ? `${helpBase}remote-auth?n=${token}&r=${articleUrl}` : articleUrl;
    }

    /**
     * returns:
     * token
     * Need to get a new one each time.  A remote token is not reusable
     *
     * KO session expires after 2 hours https://support.knowledgeowl.com/help/editor-session-timeout-bug-fixes
     */
    public static async getRemoteTokenFromSeries25(): Promise<string> {
        const isLoggedIn = await UserprefService.getLoggedIn();
        if (!isLoggedIn) return "";

        const url = DataAccess.injectCaller("/token_request.json", "GetRemoteToken");
        const payload = {
            token_request: {
                token_id: "admin",
                remote_system: REMOTE_SYSTEM,
                token_type: "remote",
            },
        };

        const [tokenResp, error] = await S25Util.Maybe(DataAccess.post(url, payload));

        KnowledgeOwlService.token = tokenResp?.token_request?.token || "";
        if (error) console.error(error);
        return KnowledgeOwlService.token;
    }
}

let extractUrl = (event: Event) => {
    return (event?.target as HTMLAnchorElement)?.href;
};

let goToLink = (url: string) => {
    window.open(url, "_blank");
};
