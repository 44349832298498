import { TypeManagerDecorator } from "../../main/type.map.service";
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { jSith } from "../../util/jquery-replacement";
import { S25Util } from "../../util/s25-util";

export interface S25File {
    name?: string;
    size?: number;
    data?: string | ArrayBuffer;
    isChosen?: boolean;
    fileRef?: File;
}

@TypeManagerDecorator("s25-ng-file-upload")
@Component({
    selector: "s25-ng-file-upload",
    template: `
        <div>
            @if (isLoading) {
                <s25-ng-loading-inline-static></s25-ng-loading-inline-static>
            }
            <div class="upload-btn-wrapper">
                <input #fileUpload class="ngFileUpload" type="file" value="Choose a file" aria-label="Choose a file" />
                <button class="file-btn" tabindex="-1" aria-hidden="true">Upload a file</button>
            </div>
            @if (hasShowFiles) {
                <div>
                    @for (file of files; track file) {
                        <div>
                            <label
                                ><input type="checkbox" class="input-checkbox" [(ngModel)]="file.isChosen" />{{
                                    file.name
                                }}</label
                            >
                        </div>
                    }
                </div>
            }
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploadComponent implements OnInit, AfterViewInit {
    @ViewChild("fileUpload") fileUpload: ElementRef<HTMLInputElement>;

    @Input() files?: S25File[]; //array caller can use to get access to uploaded files, { name: string, size: int (units: bytes), data: base64, isChosen: boolean }
    @Input() extRegex?: string = ""; //extension regex, if provided the file name must match this regex, eg: (\\.png|\\.jpg)$ would only allow png or jpg files
    @Input() maxSizeMb?: number = 5; //max file size allowed (by front-end) in MB
    @Input() multiple?: boolean = false;
    @Input() showFiles?: boolean = true;
    @Input() cleanFileName?: boolean = true;
    @Input() readAsArrayBuffer?: boolean = false;
    @Input() onAdd?: (file: S25File) => any; //called after file is added to array of files (file variable passed to function) -- use in templates as on-add="::self.FUNCTION(file)", eg use "file" parameter

    isLoading: boolean = false;
    hasShowFiles: boolean;
    init = false;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {}

    resetFileChooser = () => {
        this.fileUpload.nativeElement.val(null);
        this.isLoading = false;
        this.cd.detectChanges();
    };

    reset = () => {
        this.resetFileChooser();
        Array.prototype.splice.apply(this.files, [0]);
        this.isLoading = false;
        this.cd.detectChanges();
    };

    ngAfterViewInit() {
        let fileElem = this.fileUpload.nativeElement;
        this.multiple && (fileElem.multiple = true);

        jSith.on(fileElem, "change.fileUpload", (changeEv: any) => {
            this.cleanFileName = S25Util.toBool(S25Util.coalesce(this.cleanFileName, true)); //somehow gets reset so we run the toBool/coalesce again...
            let files = changeEv && changeEv.target && changeEv.target.files;
            if (files && files.length) {
                jSith.forEach(files, (_, file) => {
                    if (
                        file &&
                        (!this.extRegex || file.name.toLowerCase().match(this.extRegex)) &&
                        (!this.maxSizeMb || parseInt(file.size) / 1024 / 1024 <= this.maxSizeMb)
                    ) {
                        file = new File([file], file.name.replace(/[^0-9a-z_ \.]/gi, ""), {
                            type: file.type,
                        }); //ANG-5074 the File object is read-only, so  create a new File object with a different name
                        let fileName = file.name;
                        if (fileName.indexOf("\\") > -1) {
                            //just store file name, not path
                            var f = fileName.split("\\");
                            fileName = f[f.length - 1];
                        }

                        if (this.cleanFileName) {
                            fileName = fileName.replace(/[^0-9a-z_ \.]/gi, ""); //only alphanumeric filename, with some spec chars allowed
                        }

                        let currFile: S25File = {
                            name: fileName,
                            size: S25Util.parseInt(file.size),
                            isChosen: true,
                            fileRef: file,
                            data: undefined,
                        };

                        let reader = new FileReader();

                        reader.addEventListener(
                            "loadstart",
                            () => {
                                this.isLoading = true;
                                this.cd.detectChanges();
                            },
                            false,
                        );

                        reader.addEventListener(
                            "load",
                            () => {
                                if (currFile) {
                                    currFile.data = reader.result;
                                    this.isLoading = false;
                                    this.files.push(currFile);
                                    this.onAdd && this.onAdd(currFile);
                                }
                                this.cd.detectChanges();
                            },
                            false,
                        );

                        reader.addEventListener(
                            "error",
                            (event) => {
                                this.isLoading = false;
                                alert("The file could not be read. Code: " + event.target.error.code);
                                this.resetFileChooser();
                            },
                            false,
                        );

                        if (this.readAsArrayBuffer) {
                            reader.readAsArrayBuffer(file);
                        } else {
                            reader.readAsDataURL(file);
                        }
                    } else if (file) {
                        if (this.extRegex && !file.name.toLowerCase().match(this.extRegex)) {
                            alert("The file has an invalid extension. Extensions must match: " + this.extRegex);
                            this.resetFileChooser();
                        } else if (file.size / 1024 / 1024 > this.maxSizeMb) {
                            alert("The file is larger than " + this.maxSizeMb + "MB");
                            this.resetFileChooser();
                        }
                    }
                });
            }
        });

        this.cd.detectChanges();
    }

    ngOnInit() {
        this.hasShowFiles = S25Util.toBool(S25Util.coalesce(this.showFiles, true)); //use new name bc showFiles could be truthy as a string of "false" but we want that converted to a bool before used
        this.cleanFileName = S25Util.toBool(S25Util.coalesce(this.cleanFileName, true)); //default to true; run toBool to convert truthy "false" string to actual false boolean
        this.files = this.files || [];
        this.multiple = S25Util.toBool(this.multiple);
        this.maxSizeMb = S25Util.parseInt(this.maxSizeMb) || null;
        this.init = true;
        this.cd.detectChanges();
    }
}
