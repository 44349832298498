import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { HelpTopic } from "./s25.help.service";
import { TypeManagerDecorator } from "../../main/type.map.service";

@TypeManagerDecorator("s25-ng-help-button")
@Component({
    selector: "s25-ng-help-button",
    template: `
        <s25-help-link [helpTopic]="topic">
            <s25-ng-icon [type]="'help'"></s25-ng-icon>
        </s25-help-link>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25HelpButtonComponent {
    @Input() topic: HelpTopic;
}
