//@author Devin
//updated 3/29/22 to switch to knowledgeowl

import { KnowledgeOwlService } from "./knowledge.owl.service";
import { Timeout } from "../../decorators/timeout.decorator";
import { Cache } from "../../decorators/cache.decorator";
import { UserprefService } from "../../services/userpref.service";
import { DataAccess } from "../../dataaccess/data.access";
import { S25Util } from "../../util/s25-util";

declare global {
    interface Window {}
}

const HelpBaseUrl = "https://knowledge25.knowledgeowl.com/help";
const DefaultHelp = HelpBaseUrl + "/25live";

const HelpTopics = {
    default: DefaultHelp,
    availability_home: DefaultHelp,
    basic_date_range: DefaultHelp,
    bound_reservations: `${HelpBaseUrl}/creating-event-bindings`,
    clone_related_events: `${HelpBaseUrl}/copying-related-events`,
    draft_delete: `${HelpBaseUrl}/deleting-events`,
    edit_related_events: `${HelpBaseUrl}/editing-related-events`,
    email: `${HelpBaseUrl}/emailing-event-details`,
    embedding: `${HelpBaseUrl}/embedding-25live-content`,
    document: `${HelpBaseUrl}/creating-and-managing-custom-documents`,
    event_cancel: `${HelpBaseUrl}/cancelling-events`,
    event_copy: `${HelpBaseUrl}/copying-events`,
    event_create: `${HelpBaseUrl}/creating-events-with-the-event-form`,
    event_edit: `${HelpBaseUrl}/editing-individual-events-with-the-event-form`,
    event_relations: `${HelpBaseUrl}/creating-related-events`,
    event_todo_create: `${HelpBaseUrl}/creating-to-do-tasks`,
    events_search_overview: `${HelpBaseUrl}searching`,
    events_view_single: DefaultHelp,
    favorites: `${HelpBaseUrl}/using-favorites-and-starred-items`,
    help: DefaultHelp,
    home: `${HelpBaseUrl}25live`,
    home_drafts: DefaultHelp,
    home_search: DefaultHelp,
    invoicing: `${HelpBaseUrl}/generating-an-invoice`,
    locations_search_overview: `${HelpBaseUrl}/advanced-searching-for-events-locations-organizations-resources-and-tasks`,
    locations_view_single: `${HelpBaseUrl}/viewing-location-details`,
    org_search_overview: `${HelpBaseUrl}/advanced-searching-for-events-locations-organizations-resources-and-tasks`,
    orgs_view_single: DefaultHelp,
    password_change: `${HelpBaseUrl}/working-with-user-settings`,
    profile_change: `${HelpBaseUrl}/working-with-user-settings`,
    pricing_admin: `${HelpBaseUrl}/series25-pricing`,
    publisher_admin: `${HelpBaseUrl}/managing-publisher-feeds`,
    publishing: `${HelpBaseUrl}/sending-events-to-25live-publisher`,
    register_ecommerce: DefaultHelp,
    report: `${HelpBaseUrl}/25live-reports-saving-printing-emailing-and-scheduling-reports`,
    report_run: `${HelpBaseUrl}/25live-reports-running-reports`,
    resources_search_overview: `${HelpBaseUrl}/advanced-searching-for-events-locations-organizations-resources-and-tasks`,
    resources_view_single: DefaultHelp,
    search_create: `${HelpBaseUrl}/saving-searches`,
    search_delete: DefaultHelp,
    search_simple_criteria_keyword: `${HelpBaseUrl}/quick-searching-for-events-locations-organizations-resources-and-contacts`,
    search_view: `${HelpBaseUrl}/advanced-searching-for-events-locations-organizations-resources-and-tasks`,
    set_exceptions: `${HelpBaseUrl}/setting-date-exceptions`,
    subscribe: `${HelpBaseUrl}/subscribing-to-an-event-feed`,
    subtab_events_search: DefaultHelp,
    subtab_events_search_advanced: DefaultHelp,
    subtab_events_search_predefined: DefaultHelp,
    subtab_home_calendar: DefaultHelp,
    subtab_home_dashboard: `${HelpBaseUrl}/using-and-customizing-your-home-dashboard`,
    subtab_locations_search: DefaultHelp,
    subtab_locations_search_advanced: DefaultHelp,
    subtab_locations_search_predefined: DefaultHelp,
    subtab_org_search: DefaultHelp,
    subtab_org_search_advanced: DefaultHelp,
    subtab_org_search_predefined: DefaultHelp,
    subtab_resources_search: DefaultHelp,
    subtab_resources_search_advanced: DefaultHelp,
    subtab_resources_search_predefined: DefaultHelp,
    subtab_task_search_advanced: DefaultHelp,
    system_users: `${HelpBaseUrl}/series25-system-users`,
    tab_events: DefaultHelp,
    tab_locations: DefaultHelp,
    tab_my25live: DefaultHelp,
    tab_orgs: `${HelpBaseUrl}/viewing-organization-details`,
    tab_publisher: `${HelpBaseUrl}/25live-publisher-using-publisher`,
    tab_reports: `${HelpBaseUrl}/25live-reports-running-reports`,
    tab_resources: `${HelpBaseUrl}/viewing-resource-details`,
    tab_tasks: `${HelpBaseUrl}/searching-for-tasks`,
    task_complete: `${HelpBaseUrl}/completing-tasks`,
    tasks_agenda: DefaultHelp,
    tasks_browse: DefaultHelp,
    terms: DefaultHelp,
    user_dates: `${HelpBaseUrl}/working-with-user-settings`,
    view_eventsingle_audit: DefaultHelp,
    view_eventsingle_calendar: DefaultHelp,
    view_eventsingle_detail: DefaultHelp,
    view_eventsingle_ecommerce: DefaultHelp,
    view_eventsingle_pricing: `${HelpBaseUrl}/viewing-and-modifying-event-pricing`,
    view_eventsingle_schedule: DefaultHelp,
    view_eventsingle_tasklist: DefaultHelp,
    view_loc_calendar: DefaultHelp,
    view_loc_list: DefaultHelp,
    view_loc_schedule: DefaultHelp,
    view_locsingle_calendar: DefaultHelp,
    view_locsingle_detail: DefaultHelp,
    view_locsingle_list: DefaultHelp,
    view_locsingle_schedule: DefaultHelp,
    view_locsingle_weekly: DefaultHelp,
    view_myevents_calendar: DefaultHelp,
    view_myevents_list: DefaultHelp,
    view_org_calendar: DefaultHelp,
    view_org_list: DefaultHelp,
    view_orgsingle_calendar: DefaultHelp,
    view_orgsingle_detail: DefaultHelp,
    view_orgsingle_list: DefaultHelp,
    view_res_calendar: DefaultHelp,
    view_res_list: DefaultHelp,
    view_res_schedule: DefaultHelp,
    view_ressingle_calendar: DefaultHelp,
    view_ressingle_detail: DefaultHelp,
    view_ressingle_list: DefaultHelp,
    view_ressingle_schedule: DefaultHelp,
    view_ressingle_weekly: DefaultHelp,
    view_task_search_results: DefaultHelp,
    welcome: DefaultHelp,
    series_ql: `${HelpBaseUrl}/seriesql`,
    whats_new: `${HelpBaseUrl}/whats-new`,
    bpe: `${HelpBaseUrl}/event-save-email-overview`,
    template_vars_date: `${HelpBaseUrl}/event-save-email-template-variable-reference-and-examples#formattable-date-variables`,
    document_vars_date: `${HelpBaseUrl}/document-management-variable-reference-and-examples#formattable-date-variables`,
    bulk_edit: `${HelpBaseUrl}/bulk-editing-multiple-events`,
    bulk_edit_inherit: `${HelpBaseUrl}/learning-about-the-event-type-hierarchy#how-does-data-inherit-`,
} as const;

export type HelpTopic = keyof typeof HelpTopics;

export class S25Help {
    public static getHelpUrl(helpID: HelpTopic) {
        return HelpTopics[helpID] || DefaultHelp;
    }

    public static async help(helpID: HelpTopic, event?: Event) {
        return await KnowledgeOwlService.openKORemote(event, S25Help.getHelpUrl(helpID));
    }

    @Timeout
    @Cache({ immutable: true, targetName: "KnowledgeOwlService" })
    public static async getFeedbackUrl() {
        const isLoggedIn = await UserprefService.getLoggedIn();
        if (!isLoggedIn) return "";

        const url = DataAccess.injectCaller("/token_request.json", "getFeedbackLink");
        const payload = {
            token_request: {
                token_id: "feature-user",
                remote_system: "featureupvote",
                token_type: "password",
            },
        };

        const [tokenResp, error] = await S25Util.Maybe(DataAccess.post(url, payload));

        let retVal = `https://25live.featureupvote.com/private?password=${tokenResp?.token_request?.password}`;
        if (error) console.error(error);
        return retVal;
    }
}
