//@author: devin

import { NgModule } from "@angular/core";
import { S25HelpComponent } from "./s25.help.component";
import { S25HelpButtonComponent } from "./s25.help.button.component";
import { S25IconModule } from "../s25-icon/s25.icon.module";
import { S25HelpDirective } from "./s25.help.directive";

@NgModule({
    declarations: [S25HelpComponent, S25HelpButtonComponent],
    imports: [S25IconModule, S25HelpDirective],
    providers: [S25HelpComponent, S25HelpButtonComponent],
    exports: [S25HelpComponent, S25HelpButtonComponent],
})
export class S25HelpModule {}
